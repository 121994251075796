.nav-positioned {
    position: fixed;
    left: 5%;
    max-width: 15%;
    min-width: 200px;
}

.navigation-menu {
    background-color: black;
    font-size: 2em;
    padding: 1em;
    text-align: left;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
}

.navigation-menu, .bottom-of-nav-menu {
    transition: transform 0.5s linear;
}

.navigation-menu li {
    cursor: pointer;
    border-bottom: 1px solid #525252;
    width: 100%;
    font-size: 0.75em;
}

.navigation-menu li:first-child {
    content: '';
    border-bottom: 0px solid #525252;
    width: 100%;
    display: block;
}

.company-name {
    font-size: 0.75em;
    padding-bottom: 1em;
    text-align: center;
}

.company-name + li {
    border-top: 1px solid #525252;
}

.navigation-menu ul, .navmenu-small ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.arrow-down {
    position: relative;
    display: inline-block;
    height: 0;
    text-align: center;
    color: white;
    background: black;
    line-height: 50px;
    text-decoration: none;
    padding-bottom:30%;
    background-clip:content-box;
    overflow:hidden;    
    z-index: 2;
    width: calc(100% - 3px);
    left: -1px;
}

.arrow-down::after, .arrow-down.outline::after {
    content: "";
    position: absolute;
    top:0; left: 0;
    background-color:inherit;
    padding-bottom:50%; width:57.7%;
    z-index:-1;
    
    -webkit-transform-origin:0 0;
    -ms-transform-origin:0 0;
    transform-origin:0 0;
    
    -webkit-transform: rotate(-30deg) skewX(30deg);;
    -ms-transform: rotate(-30deg) skewX(30deg);
    transform: rotate(-30deg) skewX(30deg);
}

.arrow-down.outline {
    z-index: 0;
    position: absolute;
    left: 0;
    background: none;
    width: 100%;
}

.arrow-down.outline::after {
    background: grey;
}

.expander {
    cursor: pointer;
}

.navmenu-small {
    z-index: 100;
    width: calc(100% - 2em);
    position:fixed;
    font-size: .75em;
    padding: 1em;
    background-color: black;
    display: flex;
    justify-content: space-between;
}

.navmenu-small ul:last-child {
    flex-grow: 1;
}

.navmenu-small ul:last-child li {
    padding-left: 2em;
}

.navmenu-small ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}