section {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contact-us{
    border-bottom: 1px solid white;
    padding-bottom: 1em;
}

a {
    text-decoration: none;
    color: #dedede;
}

a:hover {
    text-decoration: underline;
    color: #dedede;
}